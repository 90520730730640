const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

const redirect_uri =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:9000";

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,

  //Redirect url for authing
  redirect_uri: redirect_uri,

  googleMaps: "AIzaSyAPdDB3keeHdvNOAvgPnKZmNzAYTNG0SWw",
  systemEmailerId: 36,

  syncfusionLicenceKey: "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJjXH5ecHdXQGhfVUU="
};
