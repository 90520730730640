import React, { Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import "./styles/app.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { registerLicense } from "@syncfusion/ej2-base";
import AuthContext from "./services/AuthContext";
import config from "./config";

const TimesheetRoutes = React.lazy(() => import("./routes/TimesheetRoutes.js"));
const SicknessRoutes = React.lazy(() => import("./routes/SicknessRoutes"));
const HolidayRoutes = React.lazy(() => import("./routes/HolidayRoutes"));
const DashboardRoutes = React.lazy(() => import("./routes/DashboardRoutes"));
const CompanySettingsRoutes = React.lazy(() => import("./routes/CompanySettingsRoutes"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));

export default function Root(props) {
  const [ timedOut, setTimedOut ] = useState(false);
  const triggerTimeout = (state = true) => {
    setTimedOut(state);
  };
  let context = { ...props, timedOut, triggerTimeout };
  let history = useHistory();

  registerLicense(config.syncfusionLicenceKey);

  return (
    <AuthContext.Provider
      value={{
        ...props, timedOut, triggerTimeout
      }}
      >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    <Route 
                      path="/attend"
                      component={(route) => { return (
                        <Suspense fallback={<div>Loading...</div>}>
                          <DashboardRoutes context={context} route={route} />
                        </Suspense>
                      )}}
                    />

                    <Route 
                      path="/timesheet"
                      component={(route) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <TimesheetRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />

                    <Route 
                      path="/holiday"
                      component={(route) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <HolidayRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />

                    <Route 
                      path="/sickness"
                      component={(route) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <SicknessRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />

                    <Route
                      path="/company"
                      component={(route) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <CompanySettingsRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />

                    <Route 
                      path="*"
                      component={(route) => { return (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Dashboard context={context} route={route} />
                        </Suspense>
                      )}}
                    />


                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
