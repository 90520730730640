// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-base/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".calendar-overlay {\r\n    background-color: transparent;\r\n    position: absolute;\r\n    top: 230px;\r\n    width: 100%;\r\n    height: 800px;\r\n    /* z-index: 999999999999; */\r\n  }", "",{"version":3,"sources":["webpack://./src/styles/app.css"],"names":[],"mappings":"AAcA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,aAAa;IACb,2BAA2B;EAC7B","sourcesContent":["@import \"../../node_modules/@syncfusion/ej2-base/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-buttons/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-base/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-buttons/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-calendars/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-inputs/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-lists/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-navigations/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-popups/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css\";\r\n@import \"../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css\";\r\n\r\n.calendar-overlay {\r\n    background-color: transparent;\r\n    position: absolute;\r\n    top: 230px;\r\n    width: 100%;\r\n    height: 800px;\r\n    /* z-index: 999999999999; */\r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
